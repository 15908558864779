import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import { IOrder, OrderDeliveryType } from '../../types/IOrders';
import { ILocation, ILocations, LocationCode } from '../../types/ILocations';
import { IRequestBodyUpdateOrder } from '../../types/IRequests';

import { useApi } from '../../context/ApiProvider';
import { ApiHelper } from '../../common/ApiHelper/ApiHelper';
import { SparrowHubApiInterface, OrderLocationTransferRequest } from 'sparrowhub-client-axios';
import { useDummyData } from '../../context/DummyDataProvider';

import { Modal } from '../Modal/Modal';
import { Heading } from '../Heading/Heading';
import { Button, ButtonType } from '../Button/Button';
import { Alert, AlertIcon, AlertType } from '../Alert/Alert';
import { OrderDetails } from '../OrderDetails/OrderDetails';
import { OrderBadges } from '../OrderBadges/OrderBadges';
import { DefaultErrorMessage } from '../DefaultErrorMessage/DefaultErrorMessage';


type TransferOrderModalProps = {
  show: boolean
  order: IOrder
  partnerLocations: ILocations | null
  onClose?: Function
  onRefresh: Function
}

export const TransferOrderModal: FunctionComponent<TransferOrderModalProps> = ({ show, order, partnerLocations, onClose, onRefresh }) => {
  const { apiHelper, api }: { apiHelper: ApiHelper; api: SparrowHubApiInterface } = useApi();
  const dummyData = useDummyData();

  // state
  const [transferLocation, setTransferLocation] = useState<ILocation | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  // methods

  // Transfer Order to a new Location
  const handleTransferOrder = (locationCode: LocationCode): Promise<any> => {
    setErrorMessage('');
    return new Promise<any>((resolve, reject) => {
      if (dummyData.state.useDummyData) {
        // dummyData.mutations.setOrderStatus(order.id, orderStatus);
        resolve('');
      } else {
        // Build the Transfer Order payload
        const transferPayload: OrderLocationTransferRequest = {
          from_location_code: order.location_code,
          to_location_code: locationCode
        };

        // Make the Transfer Order request
        api.processOrderLocationTransfer(order.id, transferPayload).then((response) => {
          // Reset loader
          setIsLoading(false);

          // Refresh orders
          onRefresh();

          // Set success message and return
          setShowSuccess(true);
          setTimeout(() => {
            handleClose();
          }, 3000);
          resolve(response.data.data);
        }).catch((error) => {
          // Unable to transfer order
          console.log(error);

          // Reset loader
          setIsLoading(false);

          // Set error message and return
          setErrorMessage(`Error transferring order: ${error.response.data.message}`);
          resolve(error.response.data.message);
        });
      }
    });
  }

  // Close the Transfer Order modal  
  const handleClose = (): void => {
    setTimeout(() => {
      setShowSuccess(false);
      setTransferLocation(null);
    }, 300);

    if (onClose) onClose();
  }

  return (
    <StyledTransferOrderModal className="TransferModal">
      <Modal show={show}>
        {(transferLocation === null && !showSuccess) &&
          <>
            <Heading heading="Which store would you like to transfer this order to?" />
            <div className="TransferModal_details divider">
              <OrderBadges order={order} />
              <OrderDetails order={order} phone email bold={false} />
            </div>
            <div className="TransferModal_grid">
              {partnerLocations &&
                <>
                  {partnerLocations!
                    // temp disabled as RedactedPartner only includes enabled Locations
                    // .filter(location => location.is_enabled)
                    .map((location: ILocation, i: number) => {
                      return (
                        <Button
                          key={`location-${location.id}`}
                          type={ButtonType.Primary}
                          text={location.name}
                          disabled={location.code === order.location_code}
                          onClick={() => setTransferLocation(location)}
                        />
                      )
                    })}
                </>
              }
            </div>
            {errorMessage &&
              <div style={{ marginBottom: '20px' }}>
                <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                  <p>{errorMessage}<br /><DefaultErrorMessage /></p>
                </Alert>
              </div>
            }
            <Button type={ButtonType.Secondary} text="Cancel" onClick={handleClose} />
          </>
        }
        {(transferLocation !== null && !showSuccess) &&
          <>
            <Heading heading={`Are you sure you want to transfer this order to ${transferLocation.name}?`} />
            <div className="TransferModal_details divider">
              <OrderDetails order={order} phone email bold={false} />
            </div>
            <Button type={ButtonType.Primary} text="Yes, I'm Sure" onClick={() => handleTransferOrder(transferLocation.code)} loading={isLoading} />
            <Button type={ButtonType.Secondary} text="Back" onClick={() => { setTransferLocation(null); setErrorMessage(''); }} />
            {errorMessage &&
              <div style={{ marginTop: '20px' }}>
                <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                  <p>{errorMessage}<br /><DefaultErrorMessage /></p>
                </Alert>
              </div>
            }
          </>
        }
        {(transferLocation !== null && showSuccess) &&
          <>
            <Alert type={AlertType.PositiveSecondary} successModal>
              <p>This order has been transferred to <span className="extrabold">{transferLocation.name}</span></p>
            </Alert>
          </>
        }
      </Modal>
    </StyledTransferOrderModal>
  );
}

const StyledTransferOrderModal = styled.div`
  .Modal {
    position: relative;
    z-index: -1;

    .TransferModal_details {
      margin-top: 28px;

      .Badge {
        display: inline-flex;
        margin-right: 8px;
      }
    }

    .TransferModal_grid {
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      grid-gap: 25px 14px;
      gap: 25px 14px;
      margin: 50px 0;

      .Button {
        width: 100%;
        height: 128px;
        margin: 0 !important;
      }
    }
  }

  .Button_primary {
    margin-top: 50px;
    margin-bottom: 23px;
  }
`