import { ChangeEvent, FunctionComponent } from 'react';
import styled from 'styled-components';

import { Slider } from '../Slider/Slider';
import { TextArea } from '../TextArea/TextArea';
import { Button, ButtonColour, ButtonIcon, ButtonType } from '../Button/Button';
import { ScriptData } from '../ScriptData/ScriptData';


type ScriptInformationProps = {
  script: any
  handleChange?: Function
  handleDelete?: Function
}

export const ScriptInformation: FunctionComponent<ScriptInformationProps> = ({ script, handleChange, handleDelete }) => {
  return (
    <StyledScriptInformation className="ScriptInformation">
      <ScriptData script={script} />
      <div className="ScriptInformation_actions">
        <div className="ScriptInformation_toggles">
          <Slider label="Allow Brand Substitution" state={script.substitutionEnabled} onChange={() => { if (handleChange) handleChange(script.id, { substitutionEnabled: !script.substitutionEnabled }) }} reverse />
        </div>
        {/* <Slider label="Script Notes Added" labelFalse="No Script Notes Added" state={script.noteEnabled} onChange={() => { if (handleChange) handleChange(script.id, { noteEnabled: !script.noteEnabled }) }} /> */}
        {script.noteEnabled &&
          <TextArea id="notes-input" small grey placeholder="Add script notes here..." value={script.note} maxLength={512} onChange={(e: ChangeEvent) => { if (handleChange) handleChange(script.id, { note: (e.target as HTMLTextAreaElement).value }) }} />
        }
        <Button text="DELETE" type={ButtonType.Primary} colour={ButtonColour.RedAlt} icon={ButtonIcon.Trash} small reverse onClick={() => { if (handleDelete) handleDelete(script.id) }} />
      </div>
    </StyledScriptInformation>
  );
}

const StyledScriptInformation = styled.div`
  .ScriptInformation_actions {
    border-top: 1px solid #DADADA;

    .ScriptInformation_toggles {
      display: flex;
      margin: 11px 0 -3px 0;
    }


    .TextArea {
      margin-top: 13px;
      margin-bottom: 0;
    }

    .Button_RedAlt {
      margin-top: 21px;
    }
  }
`