import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colours } from '../../assets/css/variables';

import { NotificationType } from '../../types/INotifications';
import { ILocation } from '../../types/ILocations';
import { Location, PolicyFeature } from 'sparrowhub-client-axios';
import { formatCartLink } from '../../helpers/utils';

import { Toggle } from '../Toggle/Toggle';
import { InputField } from '../InputField/InputField';
import { TextArea } from '../TextArea/TextArea';

import messageBubbleTail from '../../assets/images/graphics/message-bubble-tail.svg';

// type PartialContact = Partial<ProcessNotificationNewRequestContact>;
// export type NotificationInput = Pick<ProcessNotificationNewRequest, 'contact' | 'message' | 'notification_type_code'>;

type NotificationPreviewInputProps = {
  feature: 'carts' | 'queue'
  location: ILocation | Location
  firstname: string
  lastname?: string
  notificationType: NotificationType.Sms | NotificationType.Email
  phone: string
  email?: string
  message: string
  onSetFirstname?: Function
  onSetLastname?: Function
  onSetNotificationType?: Function
  onSetPhone?: Function
  onSetEmail?: Function
  onSetMessage: Function
}

// Shared placeholder for the Cart reference
export const cartReferenceTemplate = '{reference}';

export const NotificationPreviewInput: FunctionComponent<NotificationPreviewInputProps> = ({ feature, location, firstname, lastname, notificationType, phone, email, message, onSetFirstname, onSetLastname, onSetNotificationType, onSetPhone, onSetEmail, onSetMessage }) => {  
  // state
  const [customMessage, setCustomMessage] = useState('');
  const [placeholder, setPlaceholder] = useState('');

  // computed
  const remainingCount = (): number => {
    const unsubLength = '\n\nUnsub: bit.ly/4gsl6BH'.length;
    return 160 - message.length - unsubLength;
  }

  // methods
  const handleToggleNotificationType = (): void => {
    if (onSetNotificationType) {
      onSetNotificationType(notificationType === NotificationType.Sms ? NotificationType.Email : NotificationType.Sms);
    }
  }
  
  const handleSetFirstname = (val: string): void => {
    if (onSetFirstname) onSetFirstname(val);
  }
  const handleSetLastname = (val: string): void => {
    if (onSetLastname) onSetLastname(val);
  }
  const handleSetPhone = (val: string): void => {
    if (onSetPhone) onSetPhone(val);
  }
  const handleSetEmail = (val: string): void => {
    if (onSetEmail) onSetEmail(val);
  }

  // watch feature to set initial message
  useEffect(() => {
    const nameVal = firstname || '{name}';
    let messageVal = '';

    switch (feature) {
      case PolicyFeature.Carts:
        messageVal = 'Your cart is ready for you to review.';
        break;
      case PolicyFeature.Queue:
        messageVal = `Hi ${nameVal}, `;
        break;
    }

    setCustomMessage(messageVal);
    setPlaceholder(messageVal);
  }, [ feature, firstname ])
 
  // watch props to generate templated message
  useEffect(() => {
    // prepare placeholder vals
    const nameVal = firstname || '{first name}';
    let messageVal = customMessage || '{custom message}';
    
    // create templated message
    let result;

    switch (feature) {
      // Carts has special handling
      case PolicyFeature.Carts:
        // add punctuation if necessary
        const eolPunctRegex = new RegExp('[!.?](?:\s+)?$(?<=)');
        if (!eolPunctRegex.test(messageVal)) messageVal += '.';
        
        if (notificationType === NotificationType.Sms) {
          result = `Hi ${nameVal}, ${messageVal} Complete your order cart.sparrowhub.com.au/c/${cartReferenceTemplate}`;
        } else {
          const signoffVal = `- The team at ${location.name}`;
          result = `Hi ${nameVal},\n\n${messageVal}\n\nClick the link below to complete your order.\n\n${signoffVal}`;
        }
        break;
      // for other features, use message directly
      default:
        result = messageVal;
        break;
    }

    

    // set message
    onSetMessage(result);
  }, [ feature, firstname, customMessage, notificationType ])

  return (
    <StyledNotificationPreviewInput className="NotificationPreviewInput">
      {onSetNotificationType &&
        <Toggle
          options={['Phone Number', 'Email Address']}
          state={notificationType === NotificationType.Email}
          onChange={handleToggleNotificationType}
          fullWidth
        />
      }
      
      {onSetFirstname && onSetLastname &&
        <div className="columns">
          <InputField type="text" id="firstname" label="First Name" placeholder="Enter customer's given name" autoComplete="off" value={firstname} onChange={(e: ChangeEvent) => handleSetFirstname((e.target as HTMLInputElement).value)} required />
          <InputField type="text" id="lastname" label="Last Name" placeholder="Enter customer's family name" autoComplete="off" value={lastname} onChange={(e: ChangeEvent) => handleSetLastname((e.target as HTMLInputElement).value)} />
        </div>
      }
      
      {notificationType === NotificationType.Sms ?
        <InputField type="text" id="phone" label="Mobile Number" placeholder="Enter customer's mobile number" autoComplete="off" value={phone} regex={/^[\d,+]+$/} onChange={(e: ChangeEvent) => handleSetPhone((e.target as HTMLInputElement).value)} required />
      :
        <InputField type="email" id="email" label="Email Address" placeholder="Enter customer's email address" autoComplete="off" value={email} onChange={(e: ChangeEvent) => handleSetEmail((e.target as HTMLInputElement).value)} required />
      }
      
      <TextArea id="cartMessage" label="Custom Message" value={customMessage} placeholder={placeholder} maxLength={512} onChange={(e: ChangeEvent) => setCustomMessage((e.target as HTMLTextAreaElement).value)} small required />

      {notificationType === NotificationType.Sms ?
        <>
          <div className="NotificationPreviewInput_preview sms">
            <p>{message}</p>
            <img src={messageBubbleTail} alt="" aria-hidden />
          </div>
          <p className={`NotificationPreviewInput_characterCount ${remainingCount() < 0 && 'invalid'}`}>
            <>{message.length} {message.length === 1 ? 'character' : 'characters'} | {remainingCount()} {remainingCount() === 1 ? 'character' : 'characters'} left</>
          </p>
        </>
      :
        <div className="NotificationPreviewInput_preview email">
          <p className="recipient">To: {email || '{email address}'}</p>
          <p>{message}</p>
          <a className="cartLink" href='' target="_blank" rel="noreferrer">Complete your order</a>
        </div>
      }
    </StyledNotificationPreviewInput>
  );
}

const StyledNotificationPreviewInput = styled.div`
  .Toggle {
    margin-bottom: 20px;
  }

  label {
    display: block;
    margin-top: 14px;
  }

  .NotificationPreviewInput_preview {
    padding: 14px;
    border-radius: 12px;
    margin-top: 9px;

    &.sms {
      position: relative;
      background: #4493F5;
      color: white;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);

      img {
        position: absolute;
        left: -9px;
        bottom: -2px;
        /* height: 20px; */
        z-index: 0;
      }
    }
    
    &.email {
      background: #F8F8F8;
      color: black;
      white-space: pre-wrap;

      p.recipient {
        color: #838383;
        padding-bottom: 8px;
        margin-bottom: 10px;
        border-bottom: 1px solid #CFCFCF;
      }
      
      a.cartLink {
        display: block;
        font-size: 0.875rem; // 14px
        color: #0dcaf0;
        margin-top: 25px;
      }
    }

    p {
      font-size: 0.875rem; // 14px
      line-height: 1.65;
      margin: 0;
      word-break: break-word;
    }
  }

  .NotificationPreviewInput_characterCount {
    font-size: 0.75rem; // 12px
    font-style: italic;
    margin-top: 8px;
    text-align: right;

    &.invalid {
      color: ${colours.urgent}
    }
  }
`