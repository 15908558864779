import { FunctionComponent, ChangeEventHandler, FocusEventHandler } from 'react';
import styled from 'styled-components';
import { colours } from '../../assets/css/variables';


type TextAreaProps = {
  id?: string
  label?: string
  placeholder?: string
  value?: any
  maxLength?: number
  small?: boolean
  grey?: boolean
  onChange?: ChangeEventHandler
  onFocus?: FocusEventHandler
  onBlur?: FocusEventHandler
  readonly?: boolean
  required?: boolean
}

export const TextArea: FunctionComponent<TextAreaProps> = ({ id, label, placeholder, value, maxLength, small, grey, onChange, onFocus, onBlur, readonly, required }) => {
  return (
    <StyledTextAreaContainer className={`${required && 'required'}`}>
      <label htmlFor={id}>{label}</label>
      <StyledTextArea
        id={id}
        placeholder={placeholder}
        value={value || ''}
        maxLength={maxLength}
        className={`TextArea ${small && 'small'} ${grey && 'grey'}`}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        readOnly={readonly}
      />
    </StyledTextAreaContainer>
  );
}

const StyledTextAreaContainer = styled.div`
  &.required {
    label:after {
      content: "*";
      margin-left: 2px;
      color: ${colours.alert};
    }
  }
`
const StyledTextArea = styled.textarea`
  width: 100%;
  height: 257px;
  margin: 8px 0 32px 0;
  padding: 13px;
  border-radius: 4px;
  border: 1px solid #9B9B9B;
  resize: none;

  &:active, &:focus-visible {
    border: 2px solid black;
    padding: 12px;
    outline: none;
  }

  &::placeholder {
    color: #D4D4D4;
  }

  &.small {
    height: 73px;
  }
  
  &.grey {
    background-color: #F6F4F4;
    border-color: #F6F4F4;
    color: black;

    &::placeholder {
      color: #AFAFAF;
    }

    &.small {
      height: 60px;
    }
  }
`